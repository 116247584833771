// array of projects to render as cards
export const projects = [
  {
    id: 1,
    overline: 'PRODUCT DESIGN',
    name: 'PangoBooks',
    header: 'Creating a seamless marketplace for book lovers',
    description: `A book marketplace with over 600k users and 2.5 million books sold, where readers can easily sell and buy books with others.`,
    overview: `Pangobooks is an online marketplace with a mission to make buying and selling books easy and accessible, while also promoting a sustainable book economy. My role was to lead all designs for both the web and app, with a focus on creating an intuitive and engaging experience for users. Collaborating closely with the engineering team, I actively contributed to the development process, building and deploying major features and tools to ensure a cohesive product. By refining user flows and optimizing the overall interface, we made it easier for readers to browse, buy, and sell books, while creating a community among book lovers.`,
    categories: ['Web', 'App', 'Product Design'],
    skills: ['Typescript', 'Next.js', 'React', 'React Native'],
    timeline: null,
    roles: ['UX Engineer'],
    projectColor: '#EBF0FF',
    projectShadow: '#C3A474',
    // links
    liveLink: 'https://pangobooks.com/',
    appStoreLink: 'https://apps.apple.com/us/app/pangobooks-buy-sell-books/id1546752104',
    githubLink: null,
    // details
    designHeader: null,
    designParagraph: null,
    codeHeader: null,
    codeParagraph: null,
    // images
    heroImgSrc: '/images/pango/pangoHero.jpg',
    videoSrc: null,
    designImg1: null,
    designImg2: null,
    designImg3: null,
    codeImg1: null,
    codeImg2: '/images/pango/pangoWeb.jpg',
    codeImg3: '/images/pango/pangoOnboarding.jpg',
  },

  {
    id: 2,
    overline: 'UX/UI',
    name: 'Precision Contractors',
    header: 'Building an elevated website for contractors to scale',
    description: `An elevated website featuring a minimal and classic design, showcasing their diverse range of services and attracting larger-scale clients to establish a strong online presence.`,
    overview: `Precision Contractor is a reputable local contractor company specializing in commercial, residential, and industrial services. I undertook the challenge of designing their website from scratch. With a focus on delivering a minimal and classic aesthetic, our goal was to establish an online presence that would support their expansion and attract larger-scale clients. Given the tight timeline, I dived into the design process, leveraging their existing logo, conducting competitor research, and collaborating closely with the client to ensure a successful outcome.`,
    categories: ['Web', 'UX/UI'],
    skills: null,
    timeline: 'April 2023',
    roles: null,
    projectColor: '#E5E5E8',
    projectShadow: '#91929D',
    // links
    liveLink: null,
    githubLink: null,
    // details
    designHeader: `Designing from the ground up`,
    designParagraph: `To begin, I conducted a comprehensive competitor audit, analyzing the websites of contractor companies in the Bay Area. With a clear understanding of Precision Contractor's vision, I started with low-fidelity wireframes, iterating the structure and layout along the way. Since Precision Contractor had no existing online presence, we spearheaded the design process, working closely with the client to ensure their preferences were met. Once the low-fidelity wireframes were finalized, I proceeded to the high-fidelity wireframes in Figma, carefully curating the color scheme, type scale, and reusable components.`,
    codeHeader: `A web presence for growth`,
    codeParagraph: `The resulting website embodies a minimal and classic aesthetic, effectively presenting Precision Contractor's services. By incorporating their existing logo into the design theme, we created a cohesive brand identity. The website showcases their range of services while maintaining a user-friendly interface. Once the design was finalized, I handed the files off to the development team. Throughout the process, we collaborated to collect specifications and implement various scenarios for adding new elements and pages.`,
    // images
    heroImgSrc: '/images/precisionContractors/pc-hero.jpg',
    videoSrc: '/images/precisionContractors/pc-recording.mp4',
    designImg1: '/images/precisionContractors/pc-colors.jpg',
    designImg2: '/images/precisionContractors/pc-ipad.jpg',
    designImg3: null,
    codeImg1: '/images/precisionContractors/pc-screens.jpg',
    codeImg2: null,
    codeImg3: null,
  },

  {
    id: 3,
    overline: 'WEB + PRODUCT DESIGN',
    name: 'Barkable',
    header: 'Developing a shopping experience for dog treats',
    description:
      'Introducing a playful and functional e-commerce site for dog treats, showcasing a seamless browsing experience, personalized design elements, and dynamic features.',
    overview: `Barkable is a concept e-commerce website that I designed and built, inspired by the creative potential of the dog product market. The goal was to create an engaging user experience and a visually delightful platform with custom illustrations and dog treat packaging. Developed during the 2021 holiday season, Barkable showcases holiday-themed elements and a personalized approach, capturing the essence of playfulness and warmth. Currently in the process of rebuilding this project in React to leverage reusable components and enhance the overall performance.`,
    categories: ['Frontend', 'UX/UI', 'Illustration'],
    skills: ['Javascript', 'SCSS', 'HTML'],
    timeline: 'Nov. 2021',
    roles: ['Developer', 'UX/UI Designer'],
    projectColor: '#57737A',
    projectShadow: '#284850',
    // links
    liveLink: null,
    githubLink: null,
    // details
    designHeader: 'Creating an entire design system',
    designParagraph: `In the design process, I focused on creating an engaging and visually appealing user experience that aligned with Barkable's brand identity. Drawing inspiration from the vibrant world of dog products, I carefully selected color schemes and fonts that strike a balance between playfulness and professionalism. Additionally, I took pride in custom designing the packaging and illustrations, including holiday-themed designs to fit the season.`,
    codeHeader: 'Coding out the designs',
    codeParagraph: `During the development process, I implemented dynamic routing for each product page, ensuring that the content is rendered based on the provided product data. I also created a product suggestion feature that recommends related items for each product page, encouraging exploration. To keep things organized, I utilized SCSS to structure the stylesheets, creating mixins and components that can be easily reused. This approach made development smoother and lays a solid foundation for future improvements.`,
    // images
    heroImgSrc: '/images/barkable/barkable-hero.jpg',
    videoSrc: '/images/barkable/barkable-recording.mp4',
    designImg1: '/images/barkable/barkableLogo.jpg',
    designImg2: '/images/barkable/barkable-colors.jpg',
    designImg3: '/images/barkable/pouch-mockup.jpg',
    designImg4: '/images/barkable/barkable-illustrations.jpg',
    designImg5: '/images/barkable/single-pouch.jpg',
    codeImg1: '/images/barkable/barkable-screens.jpg',
    codeImg2: '/images/barkable/barkable-laptop.jpg',
    codeImg3: '/images/barkable/barkable-mobile.jpg',
  },
  {
    id: 4,
    overline: 'WEB + API',
    name: 'Bookworm',
    header: 'Organize and track your reading progress',
    description: `A book lover’s dream come true - a web platform with recommendations from Google's Book API. Add, note, and track your reading progress with ease.`,
    overview: `Bookworm is a web application I developed out of my passion for reading, aiming to gamify the reading experience and promote good reading habits. It features a reading streak and a monthly book count, encouraging users to cultivate good reading habits. My primary goal was to create an engaging platform that not only allows users to track their progress but also keeps them motivated to read more. By leveraging Google's book API, users can easily search for a wide range of books and add them to their reading list. Throughout the development process, my focus was on designing an intuitive interface, optimizing performance, and ensuring scalability for future growth.`,
    categories: ['Full-stack', 'API', 'UX/UI'],
    skills: ['Angular', 'Node.js', 'Express', 'MongoDB'],
    timeline: 'Mar. 2023',
    roles: ['Developer', 'UX/UI Designer'],
    projectColor: '#E5D8C3',
    projectShadow: '#C3A474',
    // links
    liveLink: null,
    githubLink: 'https://github.com/itannady/bookworm-app',
    // details
    designHeader: 'Crafting a reader-friendly interface',
    designParagraph: `Designing Bookworm presented a challenge: how to display key information and functions without overwhelming the user. To tackle this, I included a widget for updating the user's current read on the home page, keeping it minimal yet functional. I also chose a modern yet refined teal and brown color palette that wouldn't detract from the book covers. The result is a clean, uncluttered layout that strikes the perfect balance between style and substance.`,
    codeHeader: 'Implementing optimized features',
    codeParagraph: `Coding for optimal performance was a key focus during the development of Bookworm. Several technical features were implemented to ensure a smooth and fast user experience. One of these strategies was implementing lazy loading on certain routes to improve app performance and reduce load times. Additionally, I created reusable components to maintain code quality and scalability. To ensure the security of users' data, I integrated user authentication, providing a seamless login process while adding an extra layer of protection.`,
    // images
    heroImgSrc: '/images/bookApp/bookHero.jpg',
    videoSrc: '/images/bookApp/book-recording.mp4',
    designImg1: '/images/bookApp/book-colors.jpg',
    designImg2: '/images/bookApp/book-widget.jpg',
    designImg3: null,
    codeImg1: '/images/bookApp/book-screens.jpg',
    codeImg2: null,
    codeImg3: null,
  },
  {
    id: 5,
    overline: 'WEB',
    name: 'Animal Reservation',
    header: 'A visual journey to save endangered animals',
    description: `A visually captivating and mobile-responsive website to raise awareness for endangered animals through immersive parallax design and interactive elements. `,
    overview: `Animal Reservation is a web platform dedicated to raising awareness about endangered species. My goal is to provide an engaging and visually stunning experience that informs users about the critical challenges facing at-risk animals. Animal Reservation features a mobile-responsive design and immersive parallax where users can have a seamless experience throughout the website. Through a thoughtfully crafted design, the website aims to evoke empathy and foster a deeper understanding of the importance of wildlife conservation.`,
    categories: ['Frontend', 'Design'],
    skills: ['Javascript', 'Bootstrap', 'CSS', 'HTML'],
    timeline: 'March. 2022',
    roles: ['Developer', 'UX/UI Designer'],
    projectColor: '#A78D7B',
    projectShadow: '#6D4E3D',
    // links
    liveLink: null,
    githubLink: 'https://github.com/itannady/MFW-final',
    // details
    designHeader: `Designing an immersive experience`,
    designParagraph: `My goal in designing the website was to captivate and engage users with an immersive experience that encourages them to dive deeper into an important yet often overlooked topic of endangered species. To achieve this, I leveraged JavaScript to create a captivating parallax scroll effect, blending aesthetics and interactivity. By meticulously cutting out each image in Photoshop and applying gradients through CSS, I ensured a flawless parallax experience that adds another layer of depth and engagement to the design.`,
    codeHeader: null,
    codeParagraph: null,
    // images
    heroImgSrc: '/images/animalWeb/animalHero.png',
    videoSrc: '/images/animalWeb/elephantRecording.mp4',
    designImg1: '/images/animalWeb/animal-mobile.jpg',
    designImg2: '/images/animalWeb/animal-homescreens.jpg',
    designImg3: null,
    codeImg1: null,
    codeImg2: null,
    codeImg3: null,
  },
];
